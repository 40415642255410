import { toast } from '@campus/components';
import { RequestStatus } from '@campus/commons';
import { addDidacticSequenceAndRelatedClassrooms } from '../../../../../../services/agregators';
import { getGroupActivities, reorderGroupActivities } from '../../../../../../services/pedagogicalPlanning';
import { updateClassroomsForSubsequentActivities } from '../../../../../../services/didaticSequence';
export function useActions({ store, abortSignal, pedagogicalPlanningId, newActivityIsUnforeseen, setRequestStatus, handleActivityAdded, hideModalAfterDelay }) {
    async function getUpdatedGroupActivities() {
        const response = await getGroupActivities({
            abortSignal,
            pedagogicalPlanningId
        });
        return response.data?.length >= 0 ? response.data : null;
    }
    async function reorderActivities() {
        const response = await reorderGroupActivities({
            abortSignal,
            pedagogicalPlanningId
        });
        return !response.error;
    }
    async function addActivity() {
        const state = store.getState();
        const response = await addDidacticSequenceAndRelatedClassrooms({
            abortSignal,
            impactTheMDC: state.impactTheMDC,
            planningId: pedagogicalPlanningId,
            description: state.sequenceDescription,
            classrooms: state.getAllSelectedClassrooms(),
            areClassroomsUnforeseen: newActivityIsUnforeseen,
            evaluationTypeId: state.selectedEvaluationType?.id
        });
        if (response.data) {
            return response.data;
        }
        return null;
    }
    async function updateSubsequentActivities(newActivity) {
        const classrooms = store.getState().getAllSelectedClassrooms();
        const response = await updateClassroomsForSubsequentActivities({
            abortSignal,
            didacticSequenceId: newActivity.id,
            classIds: classrooms.map((c) => c.class.id)
        });
        return !response.error;
    }
    async function getUpdatedActivitiesFailed(newActivity) {
        setRequestStatus(RequestStatus.Succeeded);
        toast.error('A atividade foi adicionada e as atividades do bloco foram reordenadas porém não foi possível recuperar as atividades do bloco atualizadas');
        handleActivityAdded({
            newActivity,
            updatedGroupActivities: null,
            otherActivitiesWereUpdated: false
        });
        hideModalAfterDelay();
    }
    function reorderFailed(newActivity) {
        setRequestStatus(RequestStatus.Succeeded);
        toast.error('A atividade foi adicionada com sucesso porém não foi possível reordenar as atividades do bloco');
        handleActivityAdded({
            newActivity,
            updatedGroupActivities: null,
            otherActivitiesWereUpdated: false
        });
        hideModalAfterDelay();
    }
    function addActivityFailed() {
        setRequestStatus(RequestStatus.Failed);
        toast.error('Falha ao adicionar atividade');
    }
    function updateSubsequentActivitiesFailed(newActivity) {
        setRequestStatus(RequestStatus.Succeeded);
        toast.error('A atividade foi adicionada com sucesso porém não foi possível alterar as aulas das atividades subsequentes');
        handleActivityAdded({
            newActivity,
            updatedGroupActivities: null,
            otherActivitiesWereUpdated: false
        });
        hideModalAfterDelay();
    }
    async function justAddActivity() {
        setRequestStatus(RequestStatus.Loading);
        const newActivity = await addActivity();
        if (!newActivity) {
            addActivityFailed();
            return;
        }
        const reorderSucceeded = await reorderActivities();
        if (!reorderSucceeded) {
            reorderFailed(newActivity);
            return;
        }
        const updatedActivities = await getUpdatedGroupActivities();
        if (!updatedActivities) {
            getUpdatedActivitiesFailed(newActivity);
            return;
        }
        setRequestStatus(RequestStatus.Succeeded);
        handleActivityAdded({
            newActivity,
            otherActivitiesWereUpdated: false,
            updatedGroupActivities: updatedActivities
        });
        hideModalAfterDelay();
    }
    async function addActivityAndUpdateOtherOnes() {
        setRequestStatus(RequestStatus.Loading);
        const newActivity = await addActivity();
        if (!newActivity) {
            addActivityFailed();
            return;
        }
        const updateSucceeded = await updateSubsequentActivities(newActivity);
        if (!updateSucceeded) {
            updateSubsequentActivitiesFailed(newActivity);
            return;
        }
        const reorderSucceeded = await reorderActivities();
        if (!reorderSucceeded) {
            reorderFailed(newActivity);
            return;
        }
        setRequestStatus(RequestStatus.Succeeded);
        handleActivityAdded({
            newActivity,
            updatedGroupActivities: null,
            otherActivitiesWereUpdated: true
        });
        hideModalAfterDelay();
    }
    return { justAddActivity, addActivityAndUpdateOtherOnes };
}
