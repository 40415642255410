import React, { useState } from 'react';
import { useWeeklyPlanningPageContext } from '../../../../../../../../../../../../../context';
import { useOptionsManager } from './hooks/useOptionsManager';
import { useStatusAndInfos } from './hooks/useStatusAndInfos';
import { useSelectedClassroom } from './hooks/useSelectedClassroom';
import { useUpdateSequenceClassrooms } from './hooks/useUpdateSequenceClassrooms';
import { getFormattedClassroomDate, getText, shouldBoxBeDisabled } from './helpers';
import { StyledWrapper, StyledMenu, StyledBox, StyledText, StyledBoxAndClassDateWrapper, StyledClassDate, StyledArrowIcon } from './styles';
const ClassBox = (props) => {
    const { availableClassrooms, userPermissions } = useWeeklyPlanningPageContext((store) => ({
        availableClassrooms: store.commonData.availableClassrooms.data,
        userPermissions: store.commonData.userPermissions.data
    }));
    const [canMenuBeDisplayed, setCanMenuBeDisplayed] = useState(false);
    const showMenu = () => setCanMenuBeDisplayed(true);
    const hideMenu = () => setCanMenuBeDisplayed(false);
    const { getInfos } = useStatusAndInfos();
    const updater = useUpdateSequenceClassrooms({
        sequence: props.sequence
    });
    const selectedClassroom = useSelectedClassroom({
        sequence: props.sequence
    });
    const { selectedOption, options } = useOptionsManager({
        selectedClassroom,
        classroomGroups: availableClassrooms
    });
    function onChange(option) {
        updater.update({ selectedOption: option, onSuccess: hideMenu });
    }
    const boxInfos = getInfos(updater.requestStatus);
    const text = getText(boxInfos, selectedOption);
    const date = getFormattedClassroomDate(boxInfos, selectedClassroom);
    const disabled = shouldBoxBeDisabled(boxInfos, props.planning, userPermissions);
    return (React.createElement(StyledWrapper, { id: "topOfBody" },
        React.createElement(StyledBoxAndClassDateWrapper, null,
            React.createElement(StyledBox, { id: props.sequence.id, "$color": boxInfos?.color, "$disabled": disabled, onClick: disabled ? null : showMenu },
                React.createElement(StyledText, { "$color": boxInfos?.color }, text),
                React.createElement(StyledArrowIcon, { "$color": boxInfos?.color })),
            date ? React.createElement(StyledClassDate, null, date) : null),
        React.createElement(StyledMenu, { options: options, selectedOption: selectedOption, canBeDisplayed: canMenuBeDisplayed, handleExternalClick: hideMenu, handleOptionClicked: onChange })));
};
export default ClassBox;
