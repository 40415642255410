import React from 'react';
import Select from 'react-select';
import { useTheme } from 'styled-components';
import { RequestStatus } from '@campus/commons';
import { useFetchGrades } from '../../hooks';
import { SelectDropdownIndicator } from '../SelectDropdownIndicator';
import { Label } from '../commonStyles';
import { styles } from './styles';
import { useSkillManagementContext } from '../../../../context';
export const SegmentAndGrade = () => {
    const theme = useTheme();
    const { events } = useSkillManagementContext();
    const { data, requestStatus } = useFetchGrades();
    function onChange(newValue) {
        if (newValue) {
            events.filters.gradeIdChanged.emit({
                newGradeId: newValue.id,
                newSegmentId: newValue.segment?.id ?? null
            });
        }
        else {
            events.filters.gradeIdChanged.emit({
                newGradeId: null,
                newSegmentId: null
            });
        }
    }
    return (React.createElement("div", null,
        React.createElement(Label, null, "Segmento/ S\u00E9rie"),
        React.createElement(Select, { options: data ?? [], styles: styles(theme), hideSelectedOptions: true, onChange: onChange, placeholder: "Selecione um segmento/s\u00E9rie", noOptionsMessage: () => 'Nenhuma opção disponível', loadingMessage: () => 'Carregando segmentos e séries...', components: {
                DropdownIndicator: SelectDropdownIndicator
            }, isLoading: requestStatus === RequestStatus.Loading, getOptionValue: (g) => g.id, getOptionLabel: ({ segment, name }) => {
                return segment?.name ? `${segment.name} - ${name}` : name;
            } })));
};
