import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useTheme } from 'styled-components';
import { RequestStatus } from '@campus/commons';
import { useSkillManagementContext } from '../../../../context';
import { useFetchDisciplines } from '../../hooks';
import { SelectDropdownIndicator } from '../SelectDropdownIndicator';
import { Label } from '../commonStyles';
import { styles } from './styles';
import { matchGradeId } from './utils';
export const CurriculumComponents = () => {
    const theme = useTheme();
    const { events } = useSkillManagementContext();
    const { data, requestStatus } = useFetchDisciplines();
    const [selectedGradeId, setSelectedGradeId] = useState(null);
    const [value, setValue] = useState(null);
    useEffect(() => {
        const newId = value ? value.id : null;
        events.filters.disciplineIdChanged.emit({ newId });
    }, [value, events.filters.disciplineIdChanged]);
    useEffect(() => {
        return events.filters.gradeIdChanged.subscribe((payload) => {
            setValue(null);
            setSelectedGradeId(payload.newGradeId ?? null);
        });
    }, [events.filters.gradeIdChanged]);
    return (React.createElement("div", null,
        React.createElement(Label, null, "Componentes Curriculares"),
        React.createElement(Select, { options: data ?? [], value: value, isClearable: false, isSearchable: true, closeMenuOnSelect: true, hideSelectedOptions: true, styles: styles(theme), onChange: (newValue) => setValue(newValue), placeholder: "Selecione um Componente Curricular", noOptionsMessage: () => 'Nenhuma opção disponível', loadingMessage: () => 'Carregando componentes curriculares...', components: {
                DropdownIndicator: SelectDropdownIndicator
            }, isLoading: requestStatus === RequestStatus.Loading, getOptionValue: ({ id }) => id, getOptionLabel: ({ name }) => name, isDisabled: !selectedGradeId, filterOption: ({ data: discipline }) => {
                return matchGradeId(discipline, selectedGradeId);
            } })));
};
