import React, { useEffect, useState } from 'react';
import { getClasroomAlert } from '../../../../../../services/classroom';
import BaseHeader from '../../../../../common/components/PageRelated/Body/components/Header';
import ToggleFullScreenButton from '../../../../../common/components/PageRelated/Body/components/Header/components/Buttons/ToggleFullScreenButton';
import SegmentGradeAndDisciplines from '../../../../../common/components/PageRelated/Body/components/Header/components/SegmentGradeAndDisciplines';
import { useWeeklyPlanningPageContext } from '../../../../context';
import AddSkillButton from './components/Buttons/AddSkill/AddSkillButton';
import MenuActions from './components/Buttons/MenuActions/MenuActions';
import ClassroomAlertIcon from './components/Buttons/ClassroomAlertIcon';
import { StyledRightSideWrapper } from './styles';
const Header = () => {
    const [classroomAlertData, setClassroomAlertData] = useState();
    const components = useWeeklyPlanningPageContext((store) => store.curriculumComponents);
    const viewMode = useWeeklyPlanningPageContext((store) => store.mainTable.viewMode);
    const nonFilteredContent = useWeeklyPlanningPageContext((store) => store.mainTable.advancedViewMode.nonFilteredContent);
    useEffect(() => {
        if (components.selectedDisciplines.length > 0) {
            getClasroomAlert({
                disciplines: components.selectedDisciplines,
                grade: components.selectedGrade,
                segment: components.selectedSegment
            }).then((response) => setClassroomAlertData(response.data));
        }
        else {
            setClassroomAlertData(null);
        }
    }, [
        components.selectedDisciplines,
        components.selectedGrade,
        components.selectedSegment,
        nonFilteredContent
    ]);
    const showClassroomAlert = (classroomAlertData && classroomAlertData.classroomDuplicates.length > 0) ||
        (classroomAlertData && classroomAlertData.classroomNotFounds.length > 0);
    return (React.createElement(BaseHeader, null,
        React.createElement("div", null,
            React.createElement(BaseHeader.Title, null),
            React.createElement(SegmentGradeAndDisciplines, { grades: components.availableGrades, segments: components.availableSegments, disciplines: components.availableDisciplines, selectedGrade: components.selectedGrade, selectedSegment: components.selectedSegment, selectedDisciplines: components.selectedDisciplines, setSelectedGrade: components.selectedGradeChanged, setSelectedSegment: components.selectedSegmentChanged, addToSelectedDisciplines: components.addToSelectedDisciplines, removeFromSelectedDisciplines: components.removeFromSelectedDisciplines })),
        React.createElement(StyledRightSideWrapper, null,
            viewMode === 'advanced' ? React.createElement(AddSkillButton, null) : null,
            React.createElement(ToggleFullScreenButton, null),
            React.createElement(MenuActions, { showClassroomAlertIcon: showClassroomAlert }),
            showClassroomAlert ? (React.createElement(ClassroomAlertIcon, { classroomAlertData: classroomAlertData })) : null)));
};
export default Header;
