import React, { useState, useMemo } from 'react';
import { AlertButton, AlertIcon, AlertPopupStyled, LinkStyled, Item, ListItems } from './styles';
const ClassroomAlertIcon = (props) => {
    const [canShowPopup, setCanShowPopup] = useState(false);
    const { classroomDuplicates, classroomNotFounds } = props.classroomAlertData;
    const combinedArray = useMemo(() => {
        return [
            ...classroomNotFounds.map(({ classId, className, classroom, prevDidacticSequenceId }) => ({
                classId,
                className,
                classroom,
                id: prevDidacticSequenceId ?? 'topOfBody',
                text: `Turma ${className} - Aula ${classroom} - Não foi encontrada`
            })),
            ...classroomDuplicates.map(({ classId, className, classroom, didacticSequenceId }) => ({
                classId,
                className,
                classroom,
                id: didacticSequenceId,
                text: `Turma ${className} - Aula ${classroom} - Duplicada`
            }))
        ].sort((a, b) => a.classroom - b.classroom);
    }, [classroomDuplicates, classroomNotFounds]);
    return (React.createElement(React.Fragment, null,
        React.createElement(AlertButton, { onClick: () => {
                setCanShowPopup(true);
            } },
            React.createElement(AlertIcon, { name: "alertTriangle" })),
        React.createElement(AlertPopupStyled, { canBeDisplayed: canShowPopup, isFullScreen: false, handleExternalClick: () => {
                setCanShowPopup(false);
            } },
            React.createElement(ListItems, null, combinedArray.length > 0
                ? combinedArray.map((value) => {
                    return (React.createElement(Item, { key: `${value.id}/${value.classId}/${value.classroom}` },
                        React.createElement(LinkStyled, { href: `#${value.id}` }, value.text)));
                })
                : null))));
};
export default ClassroomAlertIcon;
